// 0 == blank
  // 1 == rgb(204, 218, 227)
  // 2 == rgb(200, 197, 217)
  // 3 == rgb(235, 223, 231)
  // 4 == rgb(51, 106, 144)
  // 5 == rgb(34, 25, 102)
  // 6 == rgb(115, 25, 83)
  // 7 == rgb(82, 82, 82)
  const usStateIDTAMatrix = [
    [7,0,0,0,0,0,0,0,0,0,0,6],
    [0,0,0,0,0,0,0,0,0,6,7,0],
    [0,5,7,7,6,7,0,6,0,6,6,7],
    [0,6,6,6,6,7,6,6,6,6,6,0],
    [0,7,7,6,6,7,6,6,6,6,6,0],
    [0,5,7,6,7,6,6,6,6,6,0,0],
    [0,0,0,6,6,6,7,7,7,0,0,0],
    [7,0,0,0,6,0,0,6,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,7,0]
  ];
  export default usStateIDTAMatrix;
