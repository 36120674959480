const usStateIDTAData = [
  { abbr: 'AK', name: 'Alaska', profiles: 0, files: [] },
  { 
    abbr: 'ME', 
    name: 'Maine', 
    profiles: 1, 
    files: [
      {
        title: 'Maine - IDTA: 2006—2007',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      }
    ] 
  },
  { 
    abbr: 'VT', 
    name: 'Vermont', 
    profiles: 1, 
    files: [
      {
        title: 'Vermont 2023—2025',
        url: '',
        fileSize: '1%',
        status: 'current',
        round: '',
      }
    ] 
  },
  { abbr: 'NH', name: 'New Hampshire', profiles: 0, files: [] },
  {
    abbr: 'WA',
    name: 'Washington',
    profiles: 4,
    files: [
      {
        title: 'Squaxin Island - IDTA: 2005—2006',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
      {
        title: 'Washington IDTA 2003—2004',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
      {
        title: 'Washington - IDTA-IPSE: 2018—2022',
        url: '/files/ta-profiles/idta-washington-profile.pdf',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
      {
        title: 'Washington 2019—2021',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      }
    ],
  },
  { abbr: 'MT', name: 'Montana', profiles: 0, files: [] },
  { abbr: 'ND', name: 'North Dakota', profiles: 0, files: [] },
  {
    abbr: 'MN',
    name: 'Minnesota',
    profiles: 2,
    files: [
      {
        title: 'Minnesota - IDTA: 2005—2006',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
      {
        title: 'Minnesota - IDTA-IPSE: 2014—2016',
        url: '/files/ta-profiles/idta-minnesota-r1-profile.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  { abbr: 'WI', name: 'Wisconsin', profiles: 0, files: [] },
  {
    abbr: 'MI',
    name: 'Michigan',
    profiles: 2,
    files: [
      {
        title: 'Michigan - IDTA: 2003—2004',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
      {
        title: 'Michigan - IDTA - IPSE 2019—2023, 2023—2025',
        url: '',
        fileSize: '1%',
        status: 'current',
        round: '',
      }
    ],
  },
  {
    abbr: 'NY',
    name: 'New York',
    profiles: 2,
    files: [
      {
        title: 'New York - IDTA: 2006—2007',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
      {
        title: 'New York - IDTA-IPSE: 2016—2018',
        url: '/files/ta-profiles/idta-new-york-profile.pdf ',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
    ],
  },
  {
    abbr: 'MA',
    name: 'Massachusetts',
    profiles: 1,
    files: [
      {
        title: 'Massachusetts - IDTA: 2005—2006, 2023—2025',
        url: '',
        fileSize: '1%',
        status: 'current',
        round: '',
      },
    ],
  },
  { abbr: 'RI', name: 'Rhode Island', profiles: 0, files: [] },
  {
    abbr: 'ID',
    name: 'Idaho',
    profiles: 1,
    files: [
      {
        title: "Coeur D'Alene Tribe - IDTA: 2008—2009",
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
    ],
  },
  { 
    abbr: 'WY', 
    name: 'Wyoming', 
    profiles: 1, 
    files: [
      {
        title: 'Wyoming 2020—2023',
        url: '/files/ta-profiles/idta-wy-profile.pdf',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
    ] 
  },
  { 
    abbr: 'SD', 
    name: 'South Dakota', 
    profiles: 1, 
    files: [
      {
        title: 'South Dakota 2020—2022',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      }
    ] 
  },
  {
    abbr: 'IA',
    name: 'Iowa',
    profiles: 1,
    files: [
      {
        title: 'Iowa - IDTA: 2008—2009',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
    ],
  },
  { abbr: 'IL', name: 'Illinois', profiles: 0, files: [] },
  { 
    abbr: 'IN', 
    name: 'Indiana', 
    profiles: 1, 
    files: [
      {
        title: 'Indiana 2020—2021',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
    ] 
  },
  { 
    abbr: 'OH', 
    name: 'Ohio', 
    profiles: 1, 
    files: [
      {
        title: 'Ohio 2020—2022',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
    ] 
  },
  {
    abbr: 'PA',
    name: 'Pennsylvania',
    profiles: 1,
    files: [
      {
        title: 'Pennsylvania - IDTA: 2014—2015',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
    ],
  },
  {
    abbr: 'NJ',
    name: 'New Jersey',
    profiles: 2,
    files: [
      {
        title: 'New Jersey - IDTA: 2009—2010',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
      {
        title: 'New Jersey - IDTA-IPSE: 2014—2016, 2023—2025',
        url: '/files/ta-profiles/idta-new-jersey-r1-profile.pdf ',
        fileSize: '1%',
        status: 'current',
        round: '1',
      },
    ],
  },
  {
    abbr: 'CT',
    name: 'Connecticut',
    profiles: 2,
    files: [
      {
        title: 'Connecticut - IDTA: 2008—2009, 2023—2025',
        url: '',
        fileSize: '1%',
        status: 'current',
        round: '',
      },
      {
        title: 'Connecticut - IDTA-IPSE: 2014—2016',
        url: '/files/ta-profiles/idta-ct-r1-profile.pdf ',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  { abbr: 'OR', name: 'Oregon', profiles: 0, files: [] },
  { abbr: 'NV', name: 'Nevada', profiles: 0, files: [] },
  {
    abbr: 'CO',
    name: 'Colorado',
    profiles: 1,
    files: [
      {
        title: 'Colorado - IDTA: 2003—2004',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      }
    ],
  },
  {
    abbr: 'NE',
    name: 'Nebraska',
    profiles: 2,
    files: [
      {
        title: 'Nebraska 2010-2012',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
      {
        title: 'Nebraska 2020-2022',
        url: '/files/ta-profiles/idta-ne-profile.pdf',
        fileSize: '1%',
        status: 'past',
        round: '',
      }
    ],
  },
  { abbr: 'MO', name: 'Missouri', profiles: 0, files: [] },
  {
    abbr: 'KY',
    name: 'Kentucky',
    profiles: 2,
    files: [
      {
        title: 'Kentucky - IDTA: 2010—2012',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
      {
        title: 'Kentucky - IDTA-IPSE: 2014—2016',
        url: '/files/ta-profiles/idta-kentucky-r1-profile.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  {
    abbr: 'WV',
    name: 'West Virgina',
    profiles: 1,
    files: [
      {
        title: 'West Virginia - IDTA-IPSE: 2014—2016, 2018—2020',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
    ],
  },
  {
    abbr: 'VA',
    name: 'Virginia',
    profiles: 2,
    files: [
      {
        title: 'Virginia - IDTA: 2003—2004',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
      {
        title: 'Virginia - IDTA-IPSE: 2014—2016',
        url: '/files/ta-profiles/idta-virginia-r1-profile.pdf ',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  {
    abbr: 'MD',
    name: 'Maryland',
    profiles: 1,
    files: [
      {
        title: 'Maryland - IDTA-IPSE: 2018—2020',
        url: '/files/ta-profiles/idta-maryland-profile.pdf',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
    ],
  },
  {
    abbr: 'DE',
    name: 'Delaware',
    profiles: 1,
    files: [
      {
        title: 'Delaware - IDTA-IPSE: 2016—2018',
        url: '/files/ta-profiles/idta-delaware-r2-profile.pdf ',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
    ],
  },
  {
    abbr: 'CA',
    name: 'California',
    profiles: 4,
    files: [
      {
        title: 'California - IDTA: 2009—2010',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
      {
        title: 'Orange County, CA - IDTA: 2008—2009, 2023—2025',
        url: '',
        fileSize: '1%',
        status: 'current',
        round: '',
      },
      {
        title: 'Riverside County 2020—2023, 2023—2025',
        url: '',
        fileSize: '1%',
        status: 'current',
        round: '',
      },
      {
        title: 'Sonoma County 2009—2010',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      }
    ],
  },
  { abbr: 'UT', name: 'Utah', profiles: 0, files: [] },
  { 
    abbr: 'NM', 
    name: 'New Mexico', 
    profiles: 1,
    files: [
      {
        title: 'New Mexico 2020—2022',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      }
    ] 
  },
  { abbr: 'KS', name: 'Kansas', profiles: 0, files: [] },
  {
    abbr: 'AR',
    name: 'Arkansas',
    profiles: 1,
    files: [
      {
        title: 'Arkansas - IDTA: 2005—2006; 2019—2020',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      }
    ],
  },
  { 
    abbr: 'TN', 
    name: 'Tennessee', 
    profiles: 1,
    files: [
      {
        title: 'Tennessee 2013—2014',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      }
    ] 
  },
  {
    abbr: 'NC',
    name: 'North Carolina',
    profiles: 1,
    files: [
      {
        title: 'North Carolina - IDTA-IPSE: 2018—2020; 2020—2023',
        url: '/files/ta-profiles/idta-nc-profile.pdf',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
    ],
  },
  { 
    abbr: 'SC', 
    name: 'South Carolina', 
    profiles: 1,
    files: [
      {
        title: 'South Carolina 2020—2023',
        url: '/files/ta-profiles/idta-sc-profile.pdf',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
    ] 
  },
  {
    abbr: 'DC',
    name: 'District of Columbia',
    profiles: 1,
    files: [
      {
        title: 'Washington, D.C. - IDTA: 2013—2014',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
    ],
  },
  { 
    abbr: 'AZ', 
    name: 'Arizona',
    profiles: 1, 
    files: [
      {
        title: 'Arizona 2020—2023',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      }
    ] 
  },
  { 
    abbr: 'OK', 
    name: 'Oklahoma', 
    profiles: 4,
    files: [
      {
        title: 'Oklahoma (Chickasaw Nation) 2023-2025',
        url: '',
        fileSize: '1%',
        status: 'current',
        round: '',
      },
      {
        title: 'Oklahoma 2014-2015',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
      {
        title: 'Muscogee Creek Nation 2019-2020',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
      {
        title: 'Oklahoma 2020-2022',
        url: '/files/ta-profiles/idta-ok-profile.pdf',
        fileSize: '1%',
        status: 'past',
        round: '',
      }
    ] 
  },
  { 
    abbr: 'LA', 
    name: 'Louisiana', 
    profiles: 1,
    files: [
      {
        title: 'Louisiana 2023—2025',
        url: '',
        fileSize: '1%',
        status: 'current',
        round: '',
      }
    ] 
  },
  { abbr: 'MS', name: 'Mississippi', profiles: 0, files: [] },
  { abbr: 'AL', name: 'Alabama', profiles: 0, files: [] },
  { abbr: 'GA', name: 'Georgia', profiles: 0, files: [] },
  { abbr: 'HI', name: 'Hawaii', profiles: 0, files: [] },
  {
    abbr: 'TX',
    name: 'Texas',
    profiles: 1,
    files: [
      {
        title: 'Texas - IDTA: 2006—2007, 2023—2025',
        url: '',
        fileSize: '1%',
        status: 'current',
        round: '',
      },
    ],
  },
  {
    abbr: 'FL',
    name: 'Florida',
    profiles: 3,
    files: [
      {
        title: 'Florida - IDTA: 2003—2004',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
      {
        title: 'Florida - IDTA-IPSE: 2018—2020',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
      {
        title: 'Seminole Tribe - IDTA: 2009—2010',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '',
      },
    ],
  },
  { abbr: 'PR', name: 'Puerto Rico', profiles: 0, files: [] },
];
export default usStateIDTAData;
