import React from 'react';
import RpgIdtaLayout from "templates/rpg-idta-layout.js";
import usStateIDTAMatrix from "data/idta-map-colors.js";
import usStateIDTAData from "data/idta-map-data.js";
import {useStrapiIdtaPageData} from "hooks";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const Idta = ({location}) => {
     let thisLocationPathname = ''

     if (isBrowser) {
         thisLocationPathname = window.location.pathname
     }
     const { strapiIdtaPage } = useStrapiIdtaPageData();

    return (
        <RpgIdtaLayout
        location={location}
        title={strapiIdtaPage?.title}
        highlightedResourcesData={strapiIdtaPage?.blocks}
        resourcesData={[...strapiIdtaPage?.resources, ...strapiIdtaPage?.videos_and_webinars, ...strapiIdtaPage?.series]}
        topicHeroImage={strapiIdtaPage?.hero_image?.background_image?.localFile}
        color="#f5f5f5"
        fixedHeroHeight={false}
        imgStyle="ta-idta-image"
        img={strapiIdtaPage?.image?.localFile}
        imgAltText={strapiIdtaPage?.image?.alternativeText ?? ""}
        description={strapiIdtaPage?.description}
        pageDescription={strapiIdtaPage?.hero_image?.description?.data?.childMarkdownRemark?.html}
        keywords={strapiIdtaPage?.keywords}
        customCurrentPage={strapiIdtaPage?.title}
        pathname={thisLocationPathname}
        usStateData={usStateIDTAData}
        usStateMatrix={usStateIDTAMatrix}
        includeDropDown={true}
        dropDownName="idta-states"
        dropDownLabel="To view a state profile, select a state or territory"
        >
            {strapiIdtaPage?.text &&
                <div
                dangerouslySetInnerHTML={{
                    __html: strapiIdtaPage?.text?.data?.childMarkdownRemark?.html,
                }}
                className="padding-top-0 unordered-list-style"/>
            }

    </RpgIdtaLayout>
    )
};

export default Idta;
